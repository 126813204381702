.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(#000000, 0.92);
  overflow-y: auto;
}

.modal__inner {
  max-width: 50rem;
  margin: 20rem auto;
  background-color: $white;
  border-radius: 1rem;
}

.modal__header {
  padding: 2rem 4rem;
  border-bottom: solid 0.1rem $gray;
  position: relative;
}

.modal__close {
  @include button-reset;
  display: block;
  position: absolute;
  width: 6rem;
  height: 6rem;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  &::before,
  &::after {
    content: '';
    display: block;
    background-color: $gray-dark;
    border-radius: 0.2rem;
    position: absolute;
    width: 2rem;
    height: 0.4rem;
    top: 50%;
    transition: background-color 0.1s ease;
  }

  // vertical bar
  &::before {
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  // horizontal bar
  &::after {
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: $black;
    }
  }
}

.modal__title {
  font-size: 1.8rem;
  text-align: center;
  margin: 0;
  font-weight: normal;
}

.modal__content {
  padding: 3rem;
}

.modal__footer {
  padding: 3rem;
  border-top: solid 0.1rem $gray;
}

.modal__primay-action {
  @include button;
}

.modal__cancel-action {
  @include button-reset;
  font-size: 1.6rem;
  margin-left: 2rem;
  // font-weight: 700;
  color: $gray-dark;
}
