.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid__item {
  flex: 0 0 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
