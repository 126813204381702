.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-bottom: solid 0.1rem $gray;
}

.header__logo {
  flex: 1;
  margin-right: 2rem;
}

.header__logo__inner {
  display: block;
  width: 10rem;
  padding: 2rem;
  color: $gray-dark;


  &:hover {
    color: $black;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  path {
    fill: currentColor;
  }
}

.header__content {
  margin: 2rem;
  flex: 1;
  display: flex;
  justify-content: center;
}

.header__user {
  margin: 2rem;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
