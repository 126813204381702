.printable-order {
  padding: 0.5in;
}

.printable-order__shots {
  display: flex;
}

.printable-order__shot {
  display: block;
  height: 1.313in;
  width: auto;
  margin-right: 0.25in;
}
