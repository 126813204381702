@mixin switch-ui($size: 3.2rem) {
  $width-ratio: 1.8;
  $switch-width: $size * $width-ratio;
  $thumb-gap: 0.2rem;
  $thumb-width: $size - ($thumb-gap * 2);

  // position: relative;

  input {
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: block;

    &::before,
    &::after {
      content: '';
      display: inline-block;
    }

    // switch background
    &::before {
      background-color: $gray;
      width: $switch-width;
      height: $size;
      border-radius: $size / 2;
      z-index: 1;
    }

   // switch thumb
    &::after {
      background-color: $white;
      width: $thumb-width;
      height: $thumb-width;
      border-radius: $thumb-width / 2;
      position: absolute;
      top: $thumb-gap;
      left: $thumb-gap;
      z-index: 2;
      transition: left 0.15s ease;
    }
  }

  // checked state
  input:checked + label {
    &::before {
      background-color: $green;
    }

    &::after {
      left: ($switch-width - $thumb-width) - $thumb-gap;
    }
  }
}
