.cart {
  display: flex;
}

.cart__item {
  @include button-reset;
  display: block;
  width: 4.4rem;
  height: 4.4rem;
  margin-right: 1rem;
  position: relative;

  &.cart__item--filled {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.15s ease;
    }

    &::before {
      background-color: $red;
      border-radius: 50%;
    }

    &::after {
      background-image: url('/trash.svg');
      background-size: 40%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.cart__item--filled:hover {
    &::before {
      opacity: 0.75;
    }

    &::after {
      opacity: 1;
    }
  }
}

.cart__item__count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 0.1rem $gray;
  font-size: 1.6rem;
  color: $gray;
}

.cart__checkout {
  @include button;
}
