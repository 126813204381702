.ui-tag {
  display: inline-block;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0.2rem 0.8rem;
  background-color: $gray;

  &.ui-tag--info {
    background-color: $blue;
  }

  &.ui-tag--warning {
    background-color: $yellow;
  }

  &.ui-tag--error {
    background-color: lighten($red, 10%);
    color: $white;
  }
}
