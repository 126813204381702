.footer {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.footer__credits {
  margin: 0 0.8rem;

  a {
    color: $black;
  }
}
