@mixin button($bg-color: $black, $color: $white, $override-only: false) {
  @if $override-only {
    background-color: $bg-color;
    color: $color;
  } @else {
    @include button-reset;
    display: inline-block;
    padding: 1rem 2rem;
    text-decoration: none;
    margin: 0;
    border-radius: 2.2rem;
    cursor: pointer;
    background-color: $bg-color;
    color: $color;
    text-align: center;
    font-weight: 400;
  }

  &:hover{
    background-color: lighten($bg-color, 10%);
  }

  &:disabled {
    background-color: $gray-dark;
    cursor: default;
  }
}
