.magnet-checkbox {
  display: block;
  position: relative;
  width: $magnet-button-width;
  height: $magnet-button-width;

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.magnet-checkbox__inner {
  display: block;
  padding: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 50% 1.5rem 50% 50%;
  transition: background-color 0.15s ease, opacity 0.15s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $pink;
  }

  input:checked + & {
    background-color: $pink;

    &:hover {
      background-color: $red;
    }
  }

  input:disabled + & {
    background-color: transparent;
    opacity: 0.5;
    cursor: default;
  }
}

.magnet-checkbox__icon {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem; // offset from corner of button
  width: 2rem; // offset from corner of button
  height: 2rem;
  opacity: 0;
  transition: all 0.15s ease;

  .magnet-checkbox__inner:hover & {
    opacity: 1;
  }

  input:disabled + .magnet-checkbox__inner & {
    opacity: 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    background-color: $white;
    border-radius: 0.2rem;
    position: absolute;
    transition: all 0.15s ease;
  }

  // vertical bar
  &::before {
    width: 0.4rem;
    height: 2rem;
    left: 0.8rem;
  }

  // horizontal bar
  &::after {
    width: 2rem;
    height: 0.4rem;
    top: 0.8rem;
  }

  // when checked
  input:checked + .magnet-checkbox__inner & {
    transform: rotate(45deg);
    opacity: 1;

    &::after {
      width: 1.2rem;
      top: 1.6rem;
    }
  }

  // when checked and hover
  input:checked + .magnet-checkbox__inner:hover & {
    &::after {
      width: 2rem;
      top: 0.8rem;
    }
  }
}
