* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $gray-light;
  color: $black;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 2rem;
  font-family: $font-family-main;
}
