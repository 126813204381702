.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.load-more__button {
  @include button;
}
