.magnet-image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $white;
}
