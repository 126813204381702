.app {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.app__header {
  flex: 0;
  position: relative;
  z-index: 1;
}

.app__body {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
