.orders-table {
  width: 100%;
  font-family: monospace;
  border-collapse: collapse;
  font-size: 1.6rem;

  th,
  td {
    padding: 2rem 0.5rem;
  }
}

.orders-table__header {
  th {
    text-align: left;
    border-bottom: solid 0.1rem $gray-dark;
    font-size: 1.4rem;
  }
}

.orders-table__row {
  cursor: pointer;

  &:hover {
    background-color: $white;
  }

  td {
    border-bottom: solid 0.1rem $gray;
  }

  &.orders-table__row--shipped {
    color: $gray-dark;

    &:hover {
      background-color: darken($white, 2%);
    }
  }
}
