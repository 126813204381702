.user-info {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: solid 0.1rem $gray;
  border-radius: 3rem;
  position: relative;

  &:hover {
    background-color: $gray-light;
  }
}

.user-info__avatar {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.user-info__name {
  display: block;
  font-size: 1.6rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.user-info__menu {
  position: absolute;
  top: 99%; // we wan't just a tiny bit of overlap here
  right: 0;
  min-width: 100%;
  display: none;

  .user-info:hover & {
    display: block;
  }
}

.user-info__menu__inner {
  border: solid 0.1rem $gray;
  border-radius: 1rem;
  padding: 0;
  background-color: $white;
  margin-top: 1rem;
}

.user-info__menu__link {
  display: block;
  font-size: 1.6rem;
  color: $black;
  padding: 1rem;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    color: lighten($black, 20%);
  }

  &.user-info__menu__link--admin {
    border-bottom: solid 0.1rem $gray;
    color: $pink;

    &:hover {
      color: darken($pink, 10%);
    }
  }
}
