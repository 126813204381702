.welcome {
  min-height: 100vh;
  overflow: hidden;
  background-color: $white;
  position: relative;
}

.welcome__inner {
  position: absolute;
  top: 3rem;
  right: 3rem;
  bottom: 3rem;
  left: 3rem;
  display: flex;
  background-color: $gray-light;
}

.welcome__left,
.welcome__right {
  width: 50%;
}

.welcome__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome__content {
  padding: 6rem;
}

.welcome__title {
  margin: 0 0 1rem;
}

.welcome__description {
  margin: 2rem 0;
}

.welcome__cta {
  @include button($bg-color: $pink);
}

.welcome__buttons {
  position: relative;
  width: 100%;
  height: 100%;
}

.welcome__button {
  position: absolute;
  transform: translate(-7rem, -7rem); // so positioning below is based on center of image
}

.welcome__button__inner {
  width: 14rem;
  height: 14rem;
}

// manually lay out button positions
[data-button-index="1"]  { top: 0; left: 0%; }
[data-button-index="2"]  { top: 8%; left: 26%; }
[data-button-index="3"]  { top: 7%; left: 73%; }
[data-button-index="4"]  { top: 30%; left: 6%; }
[data-button-index="5"]  { top: 35%; left: 92%; }
[data-button-index="6"]  { top: 38%; left: 50%; }
[data-button-index="7"]  { top: 62%; left: 0%; }
[data-button-index="8"]  { top: 72%; left: 79%; }
[data-button-index="9"]  { top: 67%; left: 38%; }
[data-button-index="10"] { top: 92%; left: 0%; }
[data-button-index="11"] { top: 94%; left: 57%; }
[data-button-index="12"] { top: 100%; left: 100%; }
