// Colors
$black: #333333;
$white: #ffffff;
$pink: #ea4f8b;
$gray-light: #f4f4f4;
$gray: #d4d4d4;
$gray-dark: #aaaaaa;
$red: #ed1c24;
$blue: #1c75bc;
$green: #63DD83;
$yellow: #FDF462;

// Sizes
$magnet-button-width: 16rem;

// Fonts
$font-family-main: 'Montserrat', sans-serif;
