.order-details {

}

.order-details__print-pdf {
  @include button;
  width: 100%;
  margin-bottom: 3rem;
}

.order-details__customer {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.order-details__customer__avatar {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.order-details__customer__details {
  margin-left: 1rem;
}

.order-details__customer__name {
  font-size: 1.6rem;
  margin: 0 0 0.2rem;
}

.order-details__customer__email {
  display: block;
  font-size: 1.6rem;
  margin: 0;
  color: $gray-dark;
}

.order-details__shots {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.order-details__shot {
  display: block;
  width: calc((100% / 3) - 1rem);
}

.order-details__meta {
  width: 100%;
  font-size: 1.4rem;
  border-collapse: collapse;
  white-space: pre-line; // to have the \n newlines actually work
  margin-bottom: 3rem;

  td {
    // border-bottom: solid 0.1rem $gray;
    padding: 0.6rem 0.5rem;
  }
}

.order-details__shipping {
  h3 {
    font-size: 1.6rem;
    margin: 0 0 1rem;
  }
}

.order-details__ship-toggle {

}
